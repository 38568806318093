import { Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import ClubsDataService from '@/services/ClubsDataService'
import SysClub from '@/types/SysClub'
import { Tournaments } from '@/services/TournamentsDataService'
import { SysTournamentCategoryId, SysTournamentRowId } from '@/types/SysTournament'
import SeasonsDataService from '@/services/SeasonsDataService'
import SysSaeson from '@/types/SysSaeson'
import MatchHistoryDataService from '@/services/MatchHistoryDataService'
import SysMatchHistory from '@/types/SysMatchHistory'
import BaseFunctions from '@/components/Utility/Base'
import MembersDataService from '@/services/MembersDataService'
import SysMember from '@/types/SysMember'
import CommonFunctions from '@/components/Utility/Common'

type playerStatisticsType = { memberId: number; memberName: string; license: number; clubName: string; noMatches: number; wonMatches: number; winRatio: number; wonSet: number; totalDartScore: number, dartingForWonSet: number; oneEighty: number; highestClose: number; fastestDarting: number; setAverage: number }
type dataReturnType = { memberMatchHistory: any; playerStatistics: any; }
export default class statistics extends Vue {
  $Notification: any
  seasonValue: { name: string, id: number }[] = []
  seasonOptions: { name: string, id: number }[] = []
  tournamentValue: { name: string, id: number}[] = []
  tournamentOptions: { name: string, id: number}[] = []
  seriesValue: { name: string, id: number }[] = []
  seriesOptions: { name: string, id: number }[] = []
  clubValue: { name: string, id: number}[] = []
  clubOptions: { name: string, id: number}[] = []
  genderValue: { name: string, id: number}[] = []
  genderOptions: { name: string, id: number}[] = [{ name: 'Kvinder', id: 1 }, { name: 'Mænd', id: 2 }]
  todaysDate = ''
  ageParameter: { name: string, age: string}[] = [{ name: 'Min. Alder', age: '' }, { name: 'Maks. Alder', age: '' }]
  licenseSearchString = ''
  searchedMemberId: number[] = []
  searchTerm = ''
  memberMatchHistory: SysMatchHistory[] = []
  tempMember: SysMember[] = []
  playerStatistics: playerStatisticsType[] = []
  private playerStatisticsGenerationInProgress = false
  oldSort = ''

  data (): dataReturnType {
    return {
      memberMatchHistory: this.memberMatchHistory,
      playerStatistics: this.playerStatistics
    }
  }

  @Watch('seasonValue')
  onSeasonValueChange () : void {
    if (this.seasonValue.length === 1 && this.tournamentValue.length === 1) {
      this.retrieveSeries()
    } else {
      this.seriesValue = []
    }
    this.retrieveStatistics()
  }

  @Watch('tournamentValue')
  onTournamentValueChange () : void {
    if (this.seasonValue.length === 1 && this.tournamentValue.length === 1) {
      this.retrieveSeries()
    } else {
      this.seriesValue = []
    }
    this.retrieveStatistics()
  }

  @Watch('seriesValue')
  onSeriesValueChange () : void {
    this.retrieveStatistics()
  }

  @Watch('searchedMemberId')
  onSearchMemberIdChange () : void {
    this.retrieveStatistics()
  }

  @Watch('clubValue')
  onClubValueChange () : void {
    this.retrieveStatistics()
  }

  @Watch('ageParameter', { deep: true })
  onChange () : void {
    const currentYear = new Date(this.todaysDate).getFullYear()
    if (this.ageParameter[0].age !== '') {
      const bornBeforeYear = currentYear - Number(this.ageParameter[0].age)
      const bornBeforeDate = new Date(new Date(this.todaysDate).setFullYear(bornBeforeYear)).toISOString().split('T')[0]
      console.log('Born Before: ' + bornBeforeDate)
    }
    if (this.ageParameter[1].age !== '') {
      const bornAfterYear = currentYear - Number(this.ageParameter[1].age)
      const bornAfterDate = new Date(new Date(this.todaysDate).setFullYear(bornAfterYear)).toISOString().split('T')[0]
      console.log('Born After: ' + bornAfterDate)
    }
  }

  public sortStatistics (sortBy: string) : void {
    switch (sortBy) {
      case 'license':
        if (this.oldSort !== sortBy) {
          this.playerStatistics.sort((a, b) => a.license - b.license)
          this.oldSort = sortBy
        } else {
          this.playerStatistics.sort((a, b) => b.license - a.license)
          this.oldSort = ''
        }
        break
      case 'name':
        if (this.oldSort !== sortBy) {
          this.playerStatistics.sort((a, b) => a.memberName.toLowerCase() !== b.memberName.toLowerCase() ? a.memberName.toLowerCase() < b.memberName.toLowerCase() ? -1 : 1 : 0)
          this.oldSort = sortBy
        } else {
          this.playerStatistics.sort((a, b) => b.memberName.toLowerCase() !== a.memberName.toLowerCase() ? b.memberName.toLowerCase() < a.memberName.toLowerCase() ? -1 : 1 : 0)
          this.oldSort = ''
        }
        break
      case 'club':
        if (this.oldSort !== sortBy) {
          this.playerStatistics.sort((a, b) => a.clubName.toLowerCase() !== b.clubName.toLowerCase() ? a.clubName.toLowerCase() < b.clubName.toLowerCase() ? -1 : 1 : 0)
          this.oldSort = sortBy
        } else {
          this.playerStatistics.sort((a, b) => b.clubName.toLowerCase() !== a.clubName.toLowerCase() ? b.clubName.toLowerCase() < a.clubName.toLowerCase() ? -1 : 1 : 0)
          this.oldSort = ''
        }
        break
      case 'noMatches':
        if (this.oldSort !== sortBy) {
          this.playerStatistics.sort((a, b) => a.noMatches - b.noMatches)
          this.oldSort = sortBy
        } else {
          this.playerStatistics.sort((a, b) => b.noMatches - a.noMatches)
          this.oldSort = ''
        }
        break
      case 'wonMatches':
        if (this.oldSort !== sortBy) {
          this.playerStatistics.sort((a, b) => a.wonMatches - b.wonMatches)
          this.oldSort = sortBy
        } else {
          this.playerStatistics.sort((a, b) => b.wonMatches - a.wonMatches)
          this.oldSort = ''
        }
        break
      case 'winRatio':
        if (this.oldSort !== sortBy) {
          this.playerStatistics.sort((a, b) => a.winRatio - b.winRatio)
          this.oldSort = sortBy
        } else {
          this.playerStatistics.sort((a, b) => b.winRatio - a.winRatio)
          this.oldSort = ''
        }
        break
      case 'dartingForWonSet':
        if (this.oldSort !== sortBy) {
          this.playerStatistics.sort((a, b) => a.dartingForWonSet - b.dartingForWonSet)
          this.oldSort = sortBy
        } else {
          this.playerStatistics.sort((a, b) => b.dartingForWonSet - a.dartingForWonSet)
          this.oldSort = ''
        }
        break
      case 'average':
        if (this.oldSort !== sortBy) {
          this.playerStatistics.sort((a, b) => a.setAverage - b.setAverage)
          this.oldSort = sortBy
        } else {
          this.playerStatistics.sort((a, b) => b.setAverage - a.setAverage)
          this.oldSort = ''
        }
        break
      case '180':
        if (this.oldSort !== sortBy) {
          this.playerStatistics.sort((a, b) => a.oneEighty - b.oneEighty)
          this.oldSort = sortBy
        } else {
          this.playerStatistics.sort((a, b) => b.oneEighty - a.oneEighty)
          this.oldSort = ''
        }
        break
      case 'highestClose':
        if (this.oldSort !== sortBy) {
          this.playerStatistics.sort((a, b) => a.highestClose - b.highestClose)
          this.oldSort = sortBy
        } else {
          this.playerStatistics.sort((a, b) => b.highestClose - a.highestClose)
          this.oldSort = ''
        }
        break
      case 'fastestDarting':
        if (this.oldSort !== sortBy) {
          this.playerStatistics.sort((a, b) => a.fastestDarting - b.fastestDarting)
          this.oldSort = sortBy
        } else {
          this.playerStatistics.sort((a, b) => b.fastestDarting - a.fastestDarting)
          this.oldSort = ''
        }
        break
    }
  }

  public getMemberName (id: number) : string {
    let retVal = '????'
    const index = this.tempMember.findIndex(el => Number(el.id) === id)
    if (index !== -1) {
      retVal = this.tempMember[index].user_id.firstname + ' ' + this.tempMember[index].user_id.lastname
    }
    return retVal
  }

  public getClubName (clubId: number) : string {
    let retVal = '????'
    const index = this.clubOptions.findIndex(el => Number(el.id) === clubId)
    if (index !== -1) {
      retVal = this.clubOptions[index].name
    }
    return retVal
  }

  public async retrieveStatistics () : Promise<void> {
    if (this.playerStatisticsGenerationInProgress) {
      return
    }

    this.playerStatisticsGenerationInProgress = true
    this.playerStatistics = []
    let retVal = ''
    const currentYear = new Date(this.todaysDate).getFullYear()

    if (this.seasonValue.length > 0) {
      for (const item of this.seasonValue) {
        retVal += '&raekke_id.saeson_id=' + item.id.toString()
      }
    }
    if (this.tournamentValue.length > 0) {
      for (const item of this.tournamentValue) {
        retVal += '&raekke_id.turneringskategori_id=' + item.id.toString()
      }
    }
    if (this.seriesValue.length > 0) {
      for (const item of this.seriesValue) {
        retVal += '&raekke_id.id=' + item.id
      }
    }

    if (this.clubValue.length > 0) {
      for (const item of this.clubValue) {
        retVal += '&medlem_id.klubber_id=' + item.id.toString()
      }
    }
    if (this.genderValue.length > 0) {
      for (const item of this.genderValue) {
        retVal += '&medlem_id.koen_id=' + item.id.toString()
      }
    }
    if (this.ageParameter[0].age !== '' && !isNaN(Number(this.ageParameter[0].age))) {
      const bornBeforeYear = currentYear - Number(this.ageParameter[0].age)
      const bornBeforeDate = new Date(new Date(this.todaysDate).setFullYear(bornBeforeYear)).toISOString().split('T')[0]
      retVal += '&medlem_id.medlem_foedselsdag_lte=' + bornBeforeDate
    }
    if (this.ageParameter[1].age !== '' && !isNaN(Number(this.ageParameter[1].age))) {
      const bornAfterYear = currentYear - Number(this.ageParameter[1].age)
      const bornAfterDate = new Date(new Date(this.todaysDate).setFullYear(bornAfterYear)).toISOString().split('T')[0]
      retVal += '&medlem_id.medlem_foedselsdag_gte=' + bornAfterDate
    }
    if (this.searchedMemberId.length > 0) {
      // console.log('MemberId: ' + this.searchedMemberId)
      for (const item of this.searchedMemberId) {
        retVal += '&medlem_id.id=' + item.toString()
      }
    }
    retVal = retVal.slice(1)
    // console.log(retVal)

    if (retVal.length > 0) {
      this.$Notification.success({ title: 'Henter kamphistorik-oplysninger, vent venligst ...' })

      try {
        this.memberMatchHistory = (await MatchHistoryDataService.getAll('', null, retVal)).data
      } catch (err) {
        this.$Notification.danger({ title: 'Noget gik galt ved indhentningen af kamphistorik-oplysninger.' })
        console.log(err)
      }

      let memberParam = ''
      if (this.memberMatchHistory.length === 0) {
        return
      }

      // FIXME: max size of URL (including parameters) should not exceed 2K bytes. (See https://stackoverflow.com/questions/2659952/maximum-length-of-http-get-request ) . DONE
      const maxLength = 1000 // Maximum two bytes per char.
      let parameterCharCnt = 0
      let indexPosMark = 0
      let stringToAdd = ''
      let posInStringMark: number[] = [0]
      let partialTempMember: SysMember[] = []

      for (const item of this.memberMatchHistory) {
        stringToAdd = '&id=' + item.medlem_id.id

        if (parameterCharCnt + stringToAdd.length > maxLength) {
          posInStringMark.push(memberParam.length)
          indexPosMark++
          parameterCharCnt = 0
        }

        memberParam += stringToAdd
        parameterCharCnt += stringToAdd.length
      }

      // for (indexPosMark = 0; indexPosMark <= (posInStringMark.length - 1); indexPosMark++) {
      //   console.log('[retrieveStatistics()] memberParam[' + indexPosMark + '] = ' + memberParam.slice(posInStringMark[indexPosMark], (indexPosMark === (posInStringMark.length - 1) ? memberParam.length : (posInStringMark[indexPosMark + 1]))))
      // }

      // console.log('[retrieveStatistics()] memberParam = ' + memberParam)

      for (indexPosMark = 0; indexPosMark <= (posInStringMark.length - 1); indexPosMark++) {
        this.$Notification.success({ title: 'Henter yderligere medlemsoplysninger, vent venligst ... (del ' + (indexPosMark + 1).toString() + ' ud af ' + posInStringMark.length.toString() + ')' })

        try {
          partialTempMember = (await MembersDataService.asyncGetAll('', null, memberParam.slice(posInStringMark[indexPosMark], (indexPosMark === (posInStringMark.length - 1) ? memberParam.length : (posInStringMark[indexPosMark + 1]))).slice(1))).data
          this.tempMember = [...this.tempMember, ...partialTempMember]
        } catch (err) {
          this.$Notification.danger({ title: 'Noget gik galt ved indhentningen af medlemsoplysninger ...' })
          console.log(err)
        }
      }

      memberParam = ''
      posInStringMark = []
      this.$Notification.success({ title: '... færdig. Starter forabejdning af de indlæste oplysninger.' })

      this.memberMatchHistory.sort((a, b) => b.created_at !== a.created_at ? b.created_at < a.created_at ? -1 : 1 : 0)
      for (const member of this.memberMatchHistory) {
        const index = this.playerStatistics.findIndex(el => Number(el.memberId) === Number(member.medlem_id.id))
        if (index === -1) {
          this.playerStatistics.push({
            memberId: Number(member.medlem_id.id),
            memberName: this.getMemberName(Number(member.medlem_id.id)),
            license: member.medlem_id.medlem_licens === null ? 0 : member.medlem_id.medlem_licens,
            clubName: this.getClubName(member.medlem_id.klubber_id === null ? 0 : member.medlem_id.klubber_id),
            noMatches: (member.antal_kampe === null ? 0 : member.antal_kampe),
            wonMatches: (member.antal_v_kampe === null ? 0 : member.antal_v_kampe),
            winRatio: 0,
            wonSet: (member.antal_v_saet === null ? 0 : member.antal_v_saet),
            totalDartScore: (member.sum_v_dart === null ? 0 : member.sum_v_dart),
            dartingForWonSet: 0,
            oneEighty: (member.antal_180 === null ? 0 : member.antal_180),
            highestClose: (member.hoejste_lukker === null ? 0 : member.hoejste_lukker),
            fastestDarting: (member.hurtigste_saet === null ? 1000 : member.hurtigste_saet),
            setAverage: 0
          })
        } else {
          const highestClose = member.hoejste_lukker === null ? 0 : member.hoejste_lukker
          const fastestDarting = member.hurtigste_saet === null ? 1000 : member.hurtigste_saet
          this.playerStatistics[index].noMatches += (member.antal_kampe === null ? 0 : member.antal_kampe)
          this.playerStatistics[index].wonMatches += (member.antal_v_kampe === null ? 0 : member.antal_v_kampe)
          this.playerStatistics[index].totalDartScore += (member.sum_v_dart === null ? 0 : member.sum_v_dart)
          this.playerStatistics[index].wonSet += (member.antal_v_saet === null ? 0 : member.antal_v_saet)
          this.playerStatistics[index].oneEighty += (member.antal_180 === null ? 0 : member.antal_180)
          this.playerStatistics[index].highestClose = highestClose > this.playerStatistics[index].highestClose ? highestClose : this.playerStatistics[index].highestClose
          this.playerStatistics[index].fastestDarting = fastestDarting < this.playerStatistics[index].fastestDarting ? fastestDarting : this.playerStatistics[index].fastestDarting
        }
      }
      this.playerStatistics.forEach(function (item) {
        const average = item.totalDartScore / item.wonSet
        item.winRatio = (item.noMatches === 0 ? 0 : (100 / item.noMatches) * item.wonMatches)
        item.dartingForWonSet = isNaN(average) ? 0 : Math.round(average * 100) / 100
        item.setAverage = isNaN(average) ? 0 : Math.round(((501 / average) * 3) * 100) / 100
      })
      // console.log(this.playerStatistics)
      this.playerStatistics.sort((a, b) => a.license - b.license)
    } else {
      console.log('No Players')
    }

    this.playerStatisticsGenerationInProgress = false
  }

  public async searchMembers () : Promise<void> {
    this.searchedMemberId = []
    this.$Notification.success({ title: 'Henter medlemsoplysninger, vent venligst ...' })

    MembersDataService.findByNameAndLicenseSearchTerm(this.searchTerm, '', null, 'medlem_status=true')
      .then((response) => {
        const temp = response.data as SysMember[]
        console.log(temp)
        this.$Notification.success({ title: '... færdigt.' })

        for (const item of temp) {
          this.searchedMemberId.push(Number(item.id))
        }

        if (this.searchedMemberId.length > 0) {
          this.retrieveStatistics()
        }

        console.log(this.searchedMemberId.length)
      })
      .catch((err) => {
        this.$Notification.danger({ title: err })
      })
  }

  public async retrieveSeasons () : Promise<void> {
    SeasonsDataService.getAll()
      .then((response) => {
        const temp = response.data as SysSaeson[]
        for (const item of temp) {
          this.seasonOptions.push({ name: item.saeson_navn, id: Number(item.id) })
        }
      })
      .catch((err) => {
        this.$Notification.danger({ title: err })
      })
  }

  public async retrieveTournamentCategory () : Promise<void> {
    Tournaments.TournamentCategoriesDataService.getAll('', null, 'turneringskategori_status=true')
      .then((response) => {
        const temp = response.data as SysTournamentCategoryId[]

        for (const item of temp) {
          this.tournamentOptions.push({ name: item.turneringskategori_navn, id: Number(item.id) })
        }
      })
      .catch((err) => {
        this.$Notification.danger({ title: err })
      })
  }

  public async retrieveSeries () : Promise<void> {
    this.seriesOptions = []
    Tournaments.TournamentRowsDataService.getAll('', null, `saeson_id.id=${this.seasonValue[0].id}&turneringskategori_id.id=${this.tournamentValue[0].id}`)
      .then((response) => {
        const temp = response.data as SysTournamentRowId[]

        for (const item of temp) {
          this.seriesOptions.push({ name: item.raekke_navn, id: Number(item.id) })
        }
      })
      .catch((err) => {
        this.$Notification.danger({ title: err })
      })
  }

  public async retrieveClubs () : Promise<void> {
    try {
      const dateResponse = await BaseFunctions.getDatetimeFromServer()
      this.todaysDate = new Date(dateResponse.data).toISOString().split('T')[0]
    } catch (err) {
      console.log(err)
      this.todaysDate = new Date().toISOString().split('T')[0]
    }
    console.log(this.todaysDate)
    ClubsDataService.getAll('', null, 'klubber_status=true')
      .then((response) => {
        const temp = response.data as SysClub[]

        for (const item of temp) {
          this.clubOptions.push({ name: item.klubber_klubnavn, id: Number(item.id) })
        }
      })
      .catch((err) => {
        this.$Notification.danger({ title: err })
      })
  }

  public resetFilter () : void {
    this.seasonValue = []
    this.tournamentValue = []
    this.seriesValue = []
    this.clubValue = []
    this.genderValue = []
    this.ageParameter[0].age = ''
    this.ageParameter[1].age = ''
    this.searchTerm = ''
    this.searchedMemberId = []
    this.playerStatistics = []
  }

  public toMemberPage (id: number) : void {
    const tempMember = this.tempMember.find(el => Number(el.id) === id)
    if (tempMember === undefined) {
      return
    }
    const memberSlug = tempMember.id + '-' + CommonFunctions.slugify(tempMember.user_id.firstname) + '_' + CommonFunctions.slugify(tempMember.user_id.lastname)
    this.$router.push({ name: 'Memberinfo', params: { slug: memberSlug } })
  }

  async mounted () : Promise<void> {
    this.retrieveSeasons()
    this.retrieveTournamentCategory()
    this.retrieveClubs()
  }
}
